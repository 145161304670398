import { Col, Container, Row } from "react-bootstrap";
import clsx from "classnames";
import classes from "./styles.module.scss";
import { Heading } from "@/ui/Heading";
import { DynamicText } from "@/ui/DynamicText";
import { useLocale } from "@/hooks/useLocale";
import Image from "@/ui/Image";

export const GallerySection = ({ className, title, pathPrefix }) => {
  const locale = useLocale();

  return (
    <div className={clsx(className, classes.images)}>
      <Container>
        {title !== false && (
          <Heading className="mb-1">
            <DynamicText
              path={title && "path" in title ? `${title.page}.${locale}` : `${pathPrefix}.title.${locale}`}
              tag="h2"
            />
          </Heading>
        )}
        <Row className="py-5">
          <Col className="px-1">
            <figure className="mb-2">
              <Image img={{ path: `${pathPrefix}.image.1`, src: "/assets/awards/7.jpg" }} size="342x256" alt="" />
            </figure>
            <figure className="mb-2 mx-md-2 mx-lg-0">
              <Image img={{ path: `${pathPrefix}.image.2`, src: "/assets/awards/1.jpg" }} size="342x256" alt="" />
            </figure>
            <figure className="mb-2">
              <Image img={{ path: `${pathPrefix}.image.3`, src: "/assets/awards/3.jpg" }} size="342x227" alt="" />
            </figure>
          </Col>
          <Col className="px-1">
            <figure className="mb-2 me-md-1 me-lg-0">
              <Image img={{ path: `${pathPrefix}.image.4`, src: "/assets/awards/2.jpg" }} size="560x374" alt="" />
            </figure>
            <figure className="mb-2 ms-md-1 ms-lg-0">
              <Image img={{ path: `${pathPrefix}.image.5`, src: "/assets/awards/4.jpg" }} size="560x373" alt="" />
            </figure>
          </Col>
          <Col className="px-1">
            <figure className="mb-2 me-md-1 me-lg-0">
              <Image img={{ path: `${pathPrefix}.image.6`, src: "/assets/awards/5.jpg" }} size="374x249" alt="" />
            </figure>
            <figure className="mb-2 ms-md-1 ms-lg-0">
              <Image img={{ path: `${pathPrefix}.image.7`, src: "/assets/awards/6.jpg" }} size="374x499" alt="" />
            </figure>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

GallerySection.defaultProps = {
  className: "py-4 py-md-5",
  title: null,
  pathPrefix: "gallery",
};
