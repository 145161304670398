export { GallerySection } from "./GallerySection";
export { AwardsSection } from "./AwardsSection";
export { CommitteeSection } from "./CommitteeSection";
export { EventsSection } from "./EventsSection";
export { JobSearchWidget } from "./JobSearchWidget";
export { ExpectationsSection } from "./ExpectationsSection";
export { HistorySection } from "./HistorySection";
export { InsightsSection } from "./InsightsSection";
export { LatestJobsSection } from "./LatestJobsSection";
export { RecruitmentProcessSection } from "./RecruitmentProcessSection";
export { SolutionsSection } from "./SolutionsSection";
export { SpecialismsSection } from "./SpecialismsSection";
export { StatisticsSection } from "./StatisticsSection";
export { ValuesSection } from "./ValuesSection";
