import clsx from "classnames";
import { useLocale } from "@/hooks/useLocale";
import { Badge, Button, Card } from "react-bootstrap";
import { DynamicText } from "@/ui/DynamicText";
import { useLink } from "@/hooks/useLink";
import Calendar from "@/assets/Calendar.svg";
import Location from "@/assets/Location.svg";
import { format } from "date-fns";
import classes from "./styles.module.scss";
import slug from "slug";

export default function UpcomingEventCard({ className, item }) {
  const link = useLink();
  const locale = useLocale();
  const [tag] = Array.isArray(item[`tags_${locale}`]) ? item[`tags_${locale}`] : [item[`tags_${locale}`]];

  return (
    <Card className={clsx(className, classes.card)}>
      <Card.Img as="div">
        <img src={item[`image_${locale}`]} alt="" />
        <Badge as="a" href={link("events.tag", slug(tag))}>
          {tag}
        </Badge>
      </Card.Img>
      <Card.Body className="p-4 pb-2 bg-light">
        <Card.Title>
          <a className="text-secondary" href={link("events.item", item.url_slug)}>
            {item[`title_${locale}`]}
          </a>
        </Card.Title>
        <div className="flex-grow-1">
          <dl>
            <dt>
              <Calendar />
            </dt>
            <dd>{format(new Date(item.date), "MMM dd yyyy")}</dd>
          </dl>
          <dl>
            <dt>
              <Location />
            </dt>
            <dd>{item.location}</dd>
          </dl>
        </div>
        <div className="d-flex justify-content-start">
          <Button variant="outline-secondary" href={link("events.item", item.url_slug)}>
            <DynamicText path={`event.card.button.${locale}`} />
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}
