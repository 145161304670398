import clsx from "classnames";
import classes from "./styles.module.scss";
import { Badge, Button, Card } from "react-bootstrap";
import { trimText } from "@/functions/trimText";
import { DynamicText } from "@/ui/DynamicText";
import { useLocale } from "@/hooks/useLocale";
import { useLink } from "@/hooks/useLink";
import Logo from "@/assets/LogoDarkEN.svg";
import { formatDistanceToNowStrict, setDefaultOptions } from "date-fns";
import { nl, enGB } from "date-fns/locale";
import { placeholders } from "@/data/placeholders";

export const InsightCard = ({ className, item, variant, btnVariant }) => {
  const locale = useLocale();
  const link = useLink();
  const href = link(`blog.item`, locale === "en" ? item.url_slug : item[`url_${locale}`]);
  const [tag] = Array.isArray(item[`tags`]) ? item[`tags`] : [item[`tags`]];

  setDefaultOptions({ locale: locale === "nl" ? nl : enGB });

  return (
    <Card className={clsx(className, classes.card, "mx-md-1")} data-variant={variant}>
      <a href={href}>
        <Badge bg={variant}>{tag}</Badge>
        {item.image ? (
          <Card.Img src={item.image} alt={item?.image_alt ?? ""} />
        ) : (
          <div className="h-100 bg-light d-flex align-items-center p-5">
            <Logo className="w-100" />
          </div>
        )}
      </a>
      <Card.Body className="p-4">
        <i>
          <span>{placeholders[`blog.card.time.new.${locale}`]}</span>
          {"published" in item && item.published
            ? `${formatDistanceToNowStrict(new Date(item.published.replace(/-/g, "/")), "yyyy")} ${
                placeholders[`blog.card.time.ago.${locale}`]
              }`
            : null}
        </i>
        <Card.Title
          as={"a"}
          href={href}
          className="mt-3 h5"
          dangerouslySetInnerHTML={{ __html: item[`title_${locale}`] }}
        />
        <Card.Text dangerouslySetInnerHTML={{ __html: trimText(item[`text_${locale}`]) }} />
        <div className="d-flex justify-content-start">
          <Button variant={`outline-${btnVariant}`} href={href}>
            <DynamicText path={`blog.card.button.${locale}`} />
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};

InsightCard.defaultProps = {
  variant: "primary",
  btnVariant: "primary",
};
