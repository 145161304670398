import clsx from "classnames";
import { Button, Col, Container, Dropdown, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { Heading } from "@/ui/Heading";
import { DynamicText } from "@/ui/DynamicText";
import { useLocale } from "@/hooks/useLocale";
import classes from "./styles.module.scss";
import ArrowDown from "@/assets/ArrowDown.svg";
import { specialisms_helper } from "@/helpers/specialisms_helper";
import { useLink } from "@/hooks/useLink";
import slug from "slug";
import Image from "@/ui/Image";
import { DynamicHtml } from "@/ui/DynamicHtml";

export const SpecialismsSection = ({ className }) => {
  const [isMobile, setMobile] = useState(true);
  const specialisms = specialisms_helper.fetch({ parent: null });
  const md = useMediaQuery("only screen and (min-width: 768px)");
  const locale = useLocale();
  const link = useLink();

  useEffect(() => {
    setMobile(!md);
  }, [md]);

  return (
    <div className={clsx(className, classes.specialisms)}>
      <Container>
        {isMobile && (
          <Heading className="mb-4">
            <DynamicText tag="h2" path={`section.specialisms.title.${locale}`} />
          </Heading>
        )}
        <div className={clsx(classes.specialisms__content)}>
          <Image img={{ path: "section.specialisms.bg", src: "/assets/specialisms/bg.png" }} size="1276x384" alt="" />
          {!isMobile && <DynamicText tag="h2" path={`section.specialisms.title.${locale}`} />}
          <Row className="mx-4 mx-md-5 pt-4 pt-md-0 px-lg-5 justify-content-center">
            {specialisms.map((i, k) => {
              return (
                <Col key={k} xs={12} md={4}>
                  <Dropdown data-dropdown={slug(i.name)} className="mb-4 mb-md-0">
                    <Dropdown.Toggle>
                      <span>{i.name}</span>
                      <ArrowDown width={35} height={35} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="p-4 pt-0" flip={false} renderOnMount={true}>
                      <DynamicHtml path={`section.specialisms.${slug(i.name)}.menu.text.${locale}`} />
                      <Button
                        variant="outline-light"
                        className="arrow-secondary justify-content-start"
                        href={link("specialisms")}
                      >
                        Go to Specialisms
                      </Button>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              );
            })}
          </Row>
        </div>
      </Container>
    </div>
  );
};

SpecialismsSection.defaultProps = {
  className: "py-4 py-md-5",
};
