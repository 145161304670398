import { useLocale } from "@/hooks/useLocale";
import { getValues } from "@/functions/getValues";
import { Container, Stack } from "react-bootstrap";
import { DynamicText } from "@/ui/DynamicText";
import { Carousel } from "@/ui/Carousel";
import { Heading } from "@/ui/Heading";
import classes from "./styles.module.scss";
import clsx from "classnames";
import { cleanMarkup } from "@/functions/cleanMarkup";

export const ValuesSection = ({ className, title, tags, variant }) => {
  const locale = useLocale();
  const values = getValues().filter((i) => (tags ? i.tags.includes(tags) : true));

  if (values.length === 0) {
    return null;
  }

  return (
    <div className={clsx(className, classes.values)} data-variant={variant}>
      <Container>
        <Heading className="mb-1" variant={variant}>
          <DynamicText path={title ? `${title.path}.${locale}` : `section.values.title.${locale}`} tag="h2" />
        </Heading>
        <Carousel
          items={values.map((i, k) => (
            <Stack key={k} className={clsx(classes.card, "flex-column flex-md-row")}>
              <div style={{ backgroundImage: `url(${i.image})` }}>
                <img src={i.icon} alt="" className="me-3 me-md-4" />
                <div className="me-md-5" dangerouslySetInnerHTML={{ __html: i[`title_${locale}`] }} />
              </div>
              <div className="p-3 ps-5 p-md-5">
                <div dangerouslySetInnerHTML={{ __html: cleanMarkup(i[`text_${locale}`]) }} />
              </div>
            </Stack>
          ))}
          indicators={false}
          controls={true}
          visibleCount={1}
        />
      </Container>
    </div>
  );
};

ValuesSection.defaultProps = {
  className: "py-4 py-md-5",
  variant: "primary",
};
