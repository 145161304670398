import { Button, Col, Container, ListGroup, ListGroupItem, Row, Stack } from "react-bootstrap";
import { DynamicText } from "@/ui/DynamicText";
import { useLocale } from "@/hooks/useLocale";
import { Heading } from "@/ui/Heading";
import { InsightCard } from "@/ui/InsightCard";
import { useEffect, useState } from "react";
import clsx from "classnames";
import { DynamicHtml } from "@/ui/DynamicHtml";
import classes from "./styles.module.scss";
import { socials } from "@/data/socials";
import ArrowRight from "@/assets/ArrowRight.svg";
import { useMediaQuery } from "@react-hook/media-query";
import { useLink } from "@/hooks/useLink";

export const InsightsSection = ({ className, variant, cardVariant, cardBtnVariant, title, items, sidebar, tags }) => {
  const [isMobile, setMobile] = useState(true);
  const md = useMediaQuery("only screen and (min-width: 768px)");
  const locale = useLocale();
  const link = useLink();
  const [limit, setLimit] = useState(3);

  useEffect(() => {
    setMobile(!md);
  }, [md]);

  useEffect(() => {
    setLimit(sidebar ? 2 : 3);
  }, [sidebar]);

  if (items.length === 0) {
    return null;
  }

  return (
    <div className={clsx(className, classes.insights)} data-variant={variant}>
      <Container className="px-2 px-md-3">
        <Row className="mb-4">
          <Col xs={12} md={8}>
            {(isMobile || !sidebar) && (
              <Heading className="mb-4" variant={variant}>
                <DynamicText
                  path={title ? `${title.path}.${locale}` : `section.insights.title.${locale}`}
                  tag="h2"
                  dangerouslySetInnerHTML={title?.placeholders ? { __html: title.placeholders[locale] } : null}
                />
              </Heading>
            )}
          </Col>
          <Col xs={12} md={4}>
            <Button
              variant={`outline-${variant}`}
              className="justify-content-start flex-row-reverse"
              href={link("blog")}
            >
              View all
            </Button>
          </Col>
        </Row>
        <Row>
          {sidebar && (
            <Col xs={12} lg={4} className="mb-1 mb-md-4 mb-lg-0">
              <div className={clsx(classes.insights__sidebar, "px-3 py-4 p-md-5 mb-5")}>
                <div className="ms-0 ms-md-4">
                  {!isMobile && (
                    <DynamicText
                      path={title ? `${title.path}.${locale}` : `section.insights.title.${locale}`}
                      tag="h2"
                    />
                  )}
                  <DynamicHtml path={`section.insights.text.${locale}`} />
                </div>
                <ListGroup>
                  {tags.map((i, k) => (
                    <ListGroupItem key={k}>
                      <a href={i.url}>
                        {i.label}
                        <ArrowRight />
                      </a>
                    </ListGroupItem>
                  ))}
                </ListGroup>
                <Stack className={clsx(classes.insights__socials, "flex-row flex-grow-0 mx-auto align-items-center")}>
                  {socials.map(({ url, icon }, k) => (
                    <a href={url} key={k} rel="nofollow" target="_blank" className="d-flex align-items-center">
                      {icon}
                    </a>
                  ))}
                </Stack>
              </div>
            </Col>
          )}
          {items.slice(0, limit).map((i, k) => (
            <Col key={k} xs={12} md={6} lg={4} className="mt-3 mt-md-0">
              <InsightCard className="h-100" item={i} variant={cardVariant} btnVariant={cardBtnVariant} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

InsightsSection.defaultProps = {
  className: "py-4 py-md-5",
  variant: "primary",
  cardVariant: "primary",
  cardBtnVariant: "primary",
  sidebar: false,
  tags: [
    { label: "All news", url: "/blog" },
    { label: "DEI", url: "/blog/tag/dei" },
    { label: "Insurance", url: "/blog/tag/insurance" },
    { label: "Technology", url: "/blog/tag/technology" },
    { label: "Banking", url: "/blog/tag/banking" },
    { label: "Gravitas News", url: "/blog/tag/gravitas-news" },
    { label: "Videos", url: "/blog/tag/videos" },
  ],
};
